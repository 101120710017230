<template>
	<el-dialog
		title="一键报警"
		:visible.sync="visible"
		width="70%"
		append-to-body
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		@open="initDialog"
		@close="handleClose"
	>
		<div class="main">
			<div class="left">
				<div class="top">
					<img src="../../assets/operate/pic.jpg" width="160" />
					<div class="driver">
						<span>车牌号:</span>
						<span
							style="color:black;font-size:20px;font-weight:bold;"
							>{{ this.car ? this.car.plateNo : "" }}</span
						>
						<p>车辆线路:{{ this.car ? this.car.lineNo : "" }}</p>
						<p>司机姓名:未知</p>
						<p>司机电话:未知</p>
						<p>所属机构:{{ this.car ? this.car.orgName : "" }}</p>
					</div>
				</div>
				<div class="mid">
					<el-row>
						<el-button class="function" @click="activeBtn(1)"
							>文本下发</el-button
						>
						<el-button class="function" @click="activeBtn(2)"
							>一键通知</el-button
						>
					</el-row>
					<el-row>
						<el-button class="function" @click="activeBtn(3)"
							>三电数据</el-button
						>
						<el-button class="function" @click="activeBtn(4)"
							>关闭报警</el-button
						>
					</el-row>
				</div>
				<div class="foot">
					<img src="../../assets/operate/alertFlag.png" />
				</div>
				<mapLoc :vehicleInfo="car"></mapLoc>
				<img
					class="full"
					@click="mapFullScreen"
					src="../../assets/operate/fullScreen_black.png"
					width="30"
				/>
			</div>
			<div class="right">
				<videoMonitor
					class="videoMonitor"
					v-bind="$attrs"
					ref="videoMonitor"
				></videoMonitor>
			</div>
		</div>
		<!-- 文本下发  -->
		<textManageDialog
			:show="showText"
			@close="closeDialog"
		></textManageDialog>
		<!-- 一键通知  -->
		<oneKeyNotice
			v-bind="$attrs"
			:show="showAlert"
			@close="closeDialog"
		></oneKeyNotice>
		<!-- 警报详情  -->
		<alertData
			:show="showAlarm"
			:webSocketData="webSocketData"
			@close="closeDialog"
		></alertData>
		<bigMap
			:show="showMap"
			:vehicleInfo="car"
			@close="closeDialog"
		></bigMap>
	</el-dialog>
</template>

<script>
import mapLoc from "./map";
import videoMonitor from "./video";
import bigMap from "./bigMap";
import textManageDialog from "@/components/textDispatch/textManageDialog";
import oneKeyNotice from "./oneKeyNotice";
import alertData from "./alertData";

export default {
	props: {
		show: {
			type: Boolean,
			required: true,
		},
		clientId: String,
		alarmTime: String,
		// alarm: Object,
	},
	components: {
		mapLoc,
		videoMonitor,
		textManageDialog,
		oneKeyNotice,
		alertData,
		bigMap,
	},
	data() {
		return {
			visible: false,
			showText: false,
			showAlert: false,
			showAlarm: false,
			car: {},
			showMap: false,

			webSocketData: null,
		};
	},
	watch: {
		show: {
			handler() {
				this.visible = this.show;
			},
			immediate: true,
		},
	},
	methods: {
		mapFullScreen() {
			this.showMap = true;
		},
		async initDialog() {
			// 根据设备号，初始化车辆信息
			if (this.clientId) {
				const result = await this.$http({
					url: "/alert/handler/queryHiStatus",
					data: {
						deviceId: this.clientId,
						time: this.alarmTime.$FixedMacTime(),
					},
				});
				this.car = result.detail;
				this.car.currentSpeed =
					result.detail.overallVehData.currentSpeed;
				this.car.gatherTime = result.detail.gatherTime;
				this.car.latLng = result.detail.posData.latLng;
				this.car.plateNo = result.detail.vehicleInfo.plate_no;
			}
			this.$refs.videoMonitor.initVideo();
		},
		closeDialog() {
			this.showText = false;
			this.showAlert = false;
			this.showAlarm = false;
			this.showMap = false;
		},
		handleClose() {
			// 视频通道连接断开
			if (this.$refs.videoMonitor) {
				this.$refs.videoMonitor.destroyVideo();
			}
			this.$emit("close");
		},
		activeBtn(btnIndex) {
			if (btnIndex === 1) {
				//显示文本下发
				this.showText = true;
			} else if (btnIndex === 2) {
				//显示一键通知
				this.showAlert = true;
			} else if (btnIndex === 3) {
				this.showAlarm = true;
			} else if (btnIndex === 4) {
				this.$confirm("确认是否关闭报警", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						this.$http({
							url: "/alert/handler/stopOneKeyAlert",
							data: {
								clientId: this.webSocketData.clientId,
							},
						}).then(() => {
							this.$message({
								type: "info",
								message: "已暂时关闭该设备的报警",
							});
						});
					})
					.catch(() => {})
					.finally(() => {
						this.handleClose();
					});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.main {
	height: 40rem;

	.left {
		width: 400px;
		position: absolute;
		display: flex;
		flex-flow: column wrap;
		z-index: 100;
		.top {
			height: 180px;
			display: flex;
			flex-flow: row nowrap;
			.driver {
				margin-left: 10px;
			}
		}

		.mid {
			margin-top: 10px;

			.el-button {
				margin-left: 50px;
				margin-bottom: 30px;
			}

			.function {
				background-color: #2e8ded;
				color: #ffffff;
			}

			.function:selected {
				background-color: #003366;
			}
		}
		.full {
			position: absolute;
			right: 10px;
			bottom: 10px;
			cursor: pointer;
		}
	}

	.right {
		height: 100%;
		position: relative;
	}
}
</style>
