/** @format */

import request from "@/api/mapRequest";
import { VUE_BAIDU_MAP_AK } from "@/config/env";

export function transFormLatLng(lat, lng) {
    // 将经纬度转换为城市名
    // http://api.map.baidu.com/geocoder?location=24.900957,118.601253&output=json&key=fbpsPAUsNE9RnaZhNApmUrjfmELngLmT
    const data = {
        location: `${lat},${lng}`,
        output: "json",
        key: VUE_BAIDU_MAP_AK,
    };
    return request({
        url: "/geocoder",
        method: "get",
        params: data,
    });
}

export function getLatLngByIp(ip) {
    const data = {
        ip: ip,
        ak: VUE_BAIDU_MAP_AK,
        coor: "bd09ll", // 百度经纬度坐标，在国测局坐标基础之上二次加密而来
    };
    return request({
        url: "/location/ip",
        method: "get",
        params: data,
    });
}
