import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

import store from '@/store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'// progress bar style
import {setToken,getToken,removeToken} from "@/utils/token"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/emergency',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    NProgress.start();
    if (to.query && to.query.token) {
      setToken(to.query.token)
      router.replace(to.path)
    }
    if (getToken()) {
      if (to.path === '/login') {
        next({path: '/'})
      } else {
        const userInfo = store.state.userInfo
        if(!userInfo.userId) {
          store.dispatch('setUser').then(() => {
            next()
          }).catch(() => {
                Message.error('没有菜单权限')
                removeToken()
                location.reload()
          })
        }else{
          next()
        }
      }
    } else {
      if (to.path === '/login') {
        next()
      } else {
        next('/login')
      }
    }
  })

router.afterEach( () => {
  NProgress.done()
})

export default router
