import request from '../request'

export function alarmTypeDelete(data) {
    return request({
        url: '/alert/type/delete',
        method: 'post',
        data
    })
}

export function saveAlarmType(data) {
    return request({
        url: '/alert/type/save',
        method: 'post',
        data
    })
}

export function updateAlarmType(data) {
    return request({
        url: '/alert/type/update',
        method: 'post',
        data
    })
}

export function disableAlertType(data) {
    return request({
        url: '/alert/type/disable',
        method: 'post',
        data
    })
}

export function alertType(data) {
    return request({
        url: '/alert/type/query',
        method: 'post',
        data
    })
}