// import request from '@/api/request'
import videoRequest from '@/api/videoRequest'
/**
 *clientId: 设备编号
 *channelId: 通道号
 *cmdType: 控制指令 0关闭音视频 1切换码流 2暂停传输 3恢复传输 4关闭双向对讲
 *closeMediaType: 关闭音视频类型 0 关闭音视频 1 只关闭音频 保留视频 2 只关闭视频 保留音频
 *changeCodeType: 切换码流类型 0主码流 1子码流
 * @export videoControl
 * @returns promise
 */
export function videoControl({clientId,channelId,cmdType,closeMediaType,changeCodeType}) {
    const data = {
        clientId,channelId,cmdType,closeMediaType,changeCodeType
    }
    return videoRequest({
        url: '/realtimeMediaUploadControl',
        method: 'get',
        params: data
    })
}

export function openChannel({clientId,channelId,codeType,mediaType,hostIP,hostPort}) {
    const data = {
        clientId,channelId,codeType,mediaType,hostIP,hostPort
    }
    return videoRequest({
      url: '/realtimeMediaUploadReq',
      method: 'get',
      params: data
    })
}

export function openHistoryChannel(params) {
    return videoRequest({
        url: '/historyMediaUploadReq',
        method: 'get',
        params
    })
}

export function historyVideoControl(params) {
    return videoRequest({
        url: '/historyMediaUploadControl',
        method: 'get',
        params
    })
}