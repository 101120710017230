import axios from 'axios'
import qs from 'qs'
import { getToken,removeToken } from '@/utils/token'
import { Message, MessageBox } from 'element-ui'
import { API_BASEURL } from '@/config/env'

const service = axios.create({
  baseURL: API_BASEURL,
  timeout: 30000,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  // emulateJSON: true
  transformRequest: [function (data) {
    return qs.stringify(data)
  }]
})


service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers.common.token = getToken()
    }
    return config
  },
  error => {
  // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const data = response.data
    if (data.code !== '0' && data.code) { // 判断token失效
      if (data.code === '401') {
        MessageBox.confirm('你的登录认证失效，可以取消继续留在该页面，或者重新登录', '确定登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          removeToken()
          location.reload()
          // store.dispatch('Logout').then(() => {
          //   location.reload() // 为了重新实例化vue-router对象 避免bug
          // })
        })
      } else if (data.code === '-1') { // 请求报错显示
        Message({
          message: data.error,
          showClose: true,
          type: 'error'
        })
        // return response
        return Promise.reject(data.error)
      }else if (data.code === '2') {
        Message({
          message: data.error,
          showClose: true,
          type: 'error'
        })
        return Promise.resolve(data)
      }
    }else { // 释放拦截的响应
      return response
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
