/** @format */

// 配置编译环境和线上环境之间的切换

let API_BASEURL = "";
let API_BAIDUMAP = "";
let API_VIDEO = "";
let API_VIDEOHOST = "";
let API_VIDEOHOSTPORT = "";
let VUE_BAIDU_MAP_AK = "";
let API_WEBSOCKET = "";
let API_ALERT_SOCKET = "";
let BASE_JSON = false;
// websocket
// const API_WEBSOCKET = 'ws://47.111.72.35:36009/genyon-admin/websocket/frontweb/';
// ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
const env = process.env;
// 开发环境地址
if (env.NODE_ENV == "development") {
    API_BASEURL = "/emergency-admin";
    API_BAIDUMAP = "/baidu";
    // API_BAIDUMAP = 'http://api.map.baidu.com'
    API_VIDEO = "/videoEQP";
    // API_VIDEO = 'http://47.99.159.78:8002'
    API_VIDEOHOST = "47.99.159.78";
    API_VIDEOHOSTPORT = 5556;
    VUE_BAIDU_MAP_AK = "fbpsPAUsNE9RnaZhNApmUrjfmELngLmT";
    API_WEBSOCKET = "wss://eres.genyon.cn";
    API_ALERT_SOCKET = "/emergency-admin/websocket/frontweb/";
}
//生产环境地址
else if (env.NODE_ENV == "production") {
    API_BASEURL = `/emergency-admin`;
    // API_BAIDUMAP = 'http://api.map.baidu.com'
    API_BAIDUMAP = "/baidu";
    // API_VIDEO = 'http://47.99.159.78:8002'
    API_VIDEO = "/videoEQP";
    API_VIDEOHOST = "47.99.159.78";
    API_VIDEOHOSTPORT = 5556;
    VUE_BAIDU_MAP_AK = "fbpsPAUsNE9RnaZhNApmUrjfmELngLmT";
    // VUE_BAIDU_MAP_AK = "WoTQzFXgO1M1fry9QEciN3LBdikwpiz4&services=&t=20180917142401"
    API_WEBSOCKET = "ws://118.31.9.199:9010";
    API_ALERT_SOCKET = "/emergency-admin/websocket/frontweb/";
}
//测试环境地址
else if (env.NODE_ENV == "test") {
    API_BASEURL = `/emergency-admin`;
    // API_BAIDUMAP = 'http://api.map.baidu.com'
    API_BAIDUMAP = "/baidu";
    // API_VIDEO = 'http://47.99.159.78:8002'
    API_VIDEO = "/videoEQP";
    API_VIDEOHOST = "47.99.159.78";
    API_VIDEOHOSTPORT = 5556;
    VUE_BAIDU_MAP_AK = "fbpsPAUsNE9RnaZhNApmUrjfmELngLmT";
    API_WEBSOCKET = "ws://118.31.9.199:9010";
    API_ALERT_SOCKET = "/emergency-admin/websocket/frontweb/";
}
export {
    API_BASEURL,
    VUE_BAIDU_MAP_AK,
    API_BAIDUMAP,
    API_VIDEO,
    API_VIDEOHOST,
    API_VIDEOHOSTPORT,
    API_WEBSOCKET,
    API_ALERT_SOCKET,
    env,
    BASE_JSON,
};
