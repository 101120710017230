// @Author: outman_qiaopeng  Date: 2018-09-12 14:44:24
import request from '@/api/request'

/**
 * 预警列表查询
 */
export function fetchList(data) {
  return request({
    url: '/alert/handler/queryPage',
    method: 'post',
    data
  })
}

/**
 * 查询三电报警
 * @param {*} data 
 */
export function queryHitStatus(data) {
  return request({
    url: '/alert/handler/queryHiStatus',
    method: 'post',
    data
  })
}
/**
 * 事故备案
 * @param {*} data 
 */
export function accRecord(data) {
  return request({
    url: '/accidentRecord/addAccidentRecord',
    method: 'post',
    data
  })
}
// 文本下发
export function sendMessage(data) {
  return request({
    url: '/alert/handler/sendMessage',
    method: 'post',
    data
  })
}

/**
 * 停止一键报警
 * @param {*} data 
 */
export function stopAlarm(data) {
  return request({
    url: '/alert/handler/stopOneKeyAlert',
    method: 'post',
    data
  })
}

/**
 * 处理预警信息
 * @param {*} data 
 */
export function handleAlert(data) {
  return request({
    url: '/alert/handler/onKeyInform',
    method: 'post',
    data
  })
}

export function alarmDetail(data) {
  return request({
    url: '/alert/handler/queryDetail',
    method: 'post',
    data
  })
}

export function queryOrgDept(data) {
  return request({
    url: '/alert/type/queryOrgDept',
    method: 'post',
    data
  })
}

export function queryVideo(data) {
  return request({
    url: '/alert/video/query',
    method: 'post',
    data
  })
}