import axios from 'axios'
import qs from 'qs'
import { API_BAIDUMAP } from '@/config/env'

const service = axios.create({
  baseURL: API_BAIDUMAP,
  timeout: 10000,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  transformRequest: [function (data) {
    return qs.stringify(data)
  }]
})
export default service
