export default [
        {
          path: '/login',
          name: 'login',
          component: () => import('../views/login')
        },
        {
          path: '/',
          name: 'main',
          component: () => import('../views/main/entry.vue'),
          redirect: '/dashboard',
          children: [{
            path: 'dashboard',
            name: 'dashboard',
            component: () => import('../views/dashboard'),
          },{
            path: '/alertInfo',
            name: 'alertInfo',
            component: () => import('../views/alarm'),
          },{
            path: '/emergencyGuideline',
            name: 'emergencyGuideline',
            component: () => import('../views/guideBook'),
            meta: {title: '应急指南', noCache: false}
          }]
        },
        {
          path: '/base',
          name: 'base',
          component: () => import('../views/main/entry.vue'),
          redirect: '/base/alertType',
          children: [{
            path: 'alertType',
            name: 'alertType',
            component: () => import('../views/alarmSetting/alarmGroup'),
          },{
            path: 'orgDept',
            name: 'orgDept',
            component: () => import('../views/alarmSetting/orgTree'),
          },{
            path: 'alertRule',
            name: 'alertRule',
            component: () => import('../views/alarmSetting/responseRule'),
          }]
        },{
          path: '/sys',
          name: 'sys',
          component: () => import('../views/main/entry.vue'),
          redirect: '/sys/user',
          meta: {title: '系统管理'},
          children: [{
            path: 'user',
            name: 'user',
            component: () => import('../views/system/user'),
            meta: {title: '用户管理', noCache: false}
          },{
            path: 'org',
            name: 'org',
            component: () => import('../views/system/org'),
            meta: {title: '组织管理', noCache: false}
          },{
            path: 'role',
            name: 'role',
            component: () => import('../views/system/role'),
            meta: {title: '角色管理', noCache: false}
          },{
            path: 'operateLog',
            name: 'operateLog',
            component: () => import('../views/system/operateLog'),
            meta: {title: '登录日志', noCache: false}
          },{
            path: 'systemLog',
            name: 'systemLog',
            component: () => import('../views/system/systemLog'),
            meta: {title: '系统日志', noCache: false}
          }]
        }
]