import request from '@/api/request'

export function queryTextMessage () {
    return request({
        url: '/remote/msg/queryPage',
        method: 'post'
    })
}

export function saveText (content,type) {
    const data = {
        content,
        type
    }
    return request({
      url: '/remote/msg/save',
      method: 'post',
      data
    })
}

export function deleteText (id) {
    const data = {
        id
    }
    return request({
      url: '/remote/msg/delete',
      method: 'post',
      data
    })
}

export function queryPage (onlineStatus, vehicleNo, pageIndex, pageSize) {
    const data = {
        onlineStatus,
        vehicleNo,
        pageIndex,
        pageSize
    }
    return request({
        url: '/message/issue/queryPage',
        method: 'post',
        data
    })
}