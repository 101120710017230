//style
import "@/common/css/resect.css";
import "@/common/css/element-variables.scss";
import '@/common/css/jy.search.scss'

import "@/styles/index.scss";
import "@/assets/iconfont/iconfont.css";
//jy 样式
import "@/common/css/jy.dialog.scss";

import "@/common/js/config.js";

// 导入ctable，cdialog
import coms from '@/components/index'
Vue.use(coms)

import Vue from "vue";
import store from "@/store";

import Util from "@/common/js/util.js";
Vue.prototype.$util = Util;

import router from "@/router/";

import App from "@/App.vue";

import "@/common/js/request.js";
import "@/common/js/protoFunc.js";

Vue.config.productionTip = false;

import ElementUI from "element-ui";
Vue.use(ElementUI);

// element
var elementSize = "medium";
if ($(window).width() < 1400) {
  elementSize = "small";
}
Vue.prototype.$ELEMENT = {
  size: elementSize,
  zIndex: 3000
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
