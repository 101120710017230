<template>
  <el-dialog
    :visible.sync="visible"
    append-to-body
    :close-on-click-modal="false"
    @close="handleClose"
    @open="openText"
    center
    width="550px"
  >
  <el-row slot="title">
      <el-col :span="5" style="color:#ffffff;font-size:14px;text-align:left;">选择模版</el-col>
  </el-row>
  <el-row>
      <i
          class="el-icon-tickets msgManager"
          style="text-decoration:underline;cursor:pointer;"
          @click="msgModel"
        >消息模版管理</i>
  </el-row>
  <el-row>
      <el-cascader
          style="width:100%;margin:10px 0;"
          :options="textList"
          size="mini"
          expand-trigger="hover"
          v-model="modelContent"
          @change="textChange"
          placeholder="请选择消息模板"
          :show-all-levels="false"
        ></el-cascader>
        <el-input type="textarea" :rows="2" placeholder="请输入文本信息" v-model="sendContent"></el-input>
  </el-row>

  <el-row style="text-align:center;margin-top:10px;">
        <el-button type="primary" @click="confirm">确定</el-button>
        <el-button @click="handleClose">取消</el-button>
  </el-row>

  <textMessageDialog :show="showAdd" @close="closeDialog"></textMessageDialog>
  </el-dialog>
</template>

<script>
import textMessageDialog from "./textMessage";
export default {
    props: {
        show: Boolean
    },
    methods: {
        handleClose() {
            this.$emit("close")
        },
        openText() {},
        msgModel() {
            this.showAdd = true
        },
        closeDialog() {
            this.showAdd = false
        },
        confirm() {},
        textChange(item) {
            this.sendContent = item[1]
        },
    },
    data() {
        return {
            visible: false,

            showAdd: false,

            sendContent: "",
            modelContent: "",
            textList: []
        }
    },
    watch: {
        show: {
            handler() {
                this.visible = this.show
            },
            immediate: true
        }
    },
    components: {
        textMessageDialog
    }
}

</script>
<style>
</style>