<template>
  <el-dialog
    :visible.sync="textIssuedDialog"
    append-to-body
    :close-on-click-modal="false"
    @close="handleClose"
    @open="openText"
    center
    width="550px"
  >
    <el-row slot="title">
      <el-col :span="5" style="color:#ffffff;font-size:14px;text-align:left;">文本下发</el-col>
    </el-row>
    <el-form :model="textSend" ref="textSend" style="padding-bottom:0; width: 100%;">
      <el-form-item style="margin-bottom:10px" label="设备:">
        <el-select v-model="textSend.value" size="mini">
          <el-option label="疲劳一体机" value="default"></el-option>
        </el-select>
        <i
          class="el-icon-tickets msgManager"
          style="margin-left: 120px;text-decoration:underline;cursor:pointer;"
          @click="msgModel"
        >消息模版管理</i>
      </el-form-item>
      <el-form-item>
        <el-cascader
          style="width:100%"
          :options="textList"
          size="mini"
          expand-trigger="hover"
          v-model="modelContent"
          @change="textChange"
          placeholder="请选择消息模板"
          :show-all-levels="false"
        ></el-cascader>
        <el-input type="textarea" :rows="2" placeholder="请输入文本信息" v-model="sendContent"></el-input>
      </el-form-item>
      <el-form-item label="标志:">
        <br />
        <el-checkbox-group v-model="checkList">
          <div>
            <el-checkbox label="1" disabled>紧急</el-checkbox>
            <el-checkbox label="4" disabled>终端显示器显示</el-checkbox>
          </div>
          <div>
            <el-checkbox label="8" disabled>TTS播读</el-checkbox>
            <el-checkbox label="16" disabled>广告屏显示</el-checkbox>
          </div>
        </el-checkbox-group>
      </el-form-item>
      <el-row style="text-align:center">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="sendOut">发 送</el-button>
      </el-row>
    </el-form>
    <textMessageDialog :show="textMessageShow" @close="closeDialog"></textMessageDialog>
  </el-dialog>
</template>

<script>
import textMessageDialog from "./textMessage";
import _ from 'loadsh'
// import { textMessage } from "@/api/map/realTime";
// import { queryTextMessage } from "@/api/map/textMessage";
export default {
  name: "textManage",
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    deviceId: String,
    isDialog: {
      type: Boolean,
      default: true
    },
    code: String, // 报警类型
  },
  data() {
    return {
      textIssuedDialog: this.show,
      textMessageShow: false,
      textSend: {
        value: "default"
      },
      sendContent: "",
      modelContent: "",
      textList: [],
      checkList: []
    };
  },
  watch: {
    show() {
      this.textIssuedDialog = this.show;
    }
  },
  methods: {
    openText() {
      this.initMessages();
    },
    msgModel() {
      this.textMessageShow = true;
    },
    initMessages() {
      // queryTextMessage().then(res => {
      //   this.textList = this.transformTreeData(res.data.detail);
      // });
    },
    transformTreeData(obj) {
      let treeData = [];
      Object.keys(obj).forEach((v) => {
        let data = {
          value: v,
          label: this.getLabelName(v),
          children: obj[v]
        };
        treeData.push(data)
      });
      return treeData
    },
    getLabelName(key) {
      switch (key) {
        case '11':
          return "打哈欠";
        case '12':
          return "抽烟";
        case '13':
          return "闭眼";
        case '14':
          return "车道偏离";
        case '15':
          return "打手机";
        case '16':
          return "视线偏离";
        case '17':
          return "前向碰撞";
        case '20':
          return "三电报警";
        default:
          break;
      }
    },
    closeDialog() {
      this.initMessages();
      this.textMessageShow = false;
    },
    textChange(item) {
      this.sendContent = item[1]
    },
    handleClose() {
      this.sendContent = "";
      this.modelContent = "";
      this.$emit("close");
    },
    sendOut() {
      if (_.isEmpty(this.deviceId)) {
        this.$message({
          message: "设备未绑定",
          showClose: true,
          type: 'warning'
        });
        return;
      }
      if (!this.sendContent) {
        this.$message({
          message: "请输入下发文本",
          showClose: true,
          type: 'warning'
        });
        return;
      }
      // textMessage({ deviceId: this.deviceId, message: this.sendContent, code: this.code })
      //   .then(res => {
      //     this.$message({
      //       message: res.data.msg,
      //       showClose: true
      //     });
      //   })
      //   .finally(() => {
      //     this.closeDialog();
      //   });
    },
  },
  components: {
    textMessageDialog
  }
};
</script>

<style scoped>
</style>
