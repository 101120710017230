<template>
  <div class="video">
    <div class="channel" style="width:100%;height:100%;background-color:black;">
      <video
        style="width:100%;height:100%;background-color:black;"
        :id="`v${channelId}`"
        :width="width"
        :height="height"
        muted
        @play="playVideo"
        @progress="getProgress"
        @pause="pauseVideo"
        @loadeddata="loadPoster"
        preload="metadata"
      ></video>
    </div>
    <div class="track">
      <img
        class="play"
        @click="selfPlay"
        src="../../assets/video/playVideo.png"
        width="30"
        v-if="!isPlay"
      />
      <img
        class="play"
        @click="selfPause"
        src="../../assets/video/pauseVideo.png"
        width="30"
        v-else
      />
      <img
        class="play"
        v-if="mode === 'history'"
        width="25"
        @click="rewind"
        src="../../assets/video/rewind.png"
      />
      <img
        class="play"
        v-if="mode === 'history'"
        width="25"
        @click="fastForward"
        src="../../assets/video/fastForward.png"
      />
      <img
        class="fullScreen"
        @click="fullScreen"
        src="../../assets/video/fullScreen.png"
        width="25"
      />

      <el-select
        v-model="currentSpeed"
        class="playMode"
        size="mini"
        v-if="mode === 'realTime'"
        :disabled="!deviceId || !isSupported"
      >
        <el-option label="流畅" :value="0.4"></el-option>
        <el-option label="原始" :value="1"></el-option>
      </el-select>

      <el-select
        v-model="codeStream"
        class="playMode"
        size="mini"
        v-if="mode === 'realTime'"
        :disabled="!deviceId || !isSupported"
      >
        <el-option label="子码流" :value="1"></el-option>
        <el-option label="主码流" :value="0"></el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import {
  videoControl,
  openChannel,
  openHistoryChannel,
  historyVideoControl
} from "@/api/video/videoControl";
import { API_VIDEOHOST, API_VIDEOHOSTPORT } from "@/config/env";
// import Wfs from './js/wfs.js';
import "./js/wfs";

export default {
  props: {
    height: {
      type: Number,
      default: 480
    },
    width: {
      type: Number,
      default: 640
    },
    extra: String,
    mode: {
      // 播放实时或者历史
      type: String,
      default: "realTime"
    },
    currentNum: Number,
    webSocketName: String,
    channelId: String,
    webSocketUrl: String,
    deviceId: String,
    isOpen: {
      // 自动播放
      type: Boolean,
      default: false
    },
    // mode:realTime history
    startEnd: {
      type: Array,
      default() {
        return [];
      }
    } //选择时间发生变化
  },
  created() {
    // if (navigator.userAgent.includes("Windows NT 6.1")) {
    //   let script = document.createElement('script')
    //   script.type = 'text/javascript'
    //   script.src = './js/wfs.js'
    //   document.getElementsByTagName('head')[0].appendChild(script)
    //   // require("./js/wfs.js")
    // } else {
    //   require("./js/wfs_new.js");
    // }
  },
  beforeDestroy() {
    // 关闭通道
    if (this.wfs) {
      this.closeVideo();
    }
  },
  data() {
    return {
      wfs: null,
      ele: null,

      isPlay: false,
      isFull: false, // 全屏状态

      codeStream: 1, // 0:主码流 1:子码流

      currentSpeed: 1, // 默认为原始

      speedLock: false, // 播放速度锁，默认关闭

      isSupported: false, // 是否支持wfs

      replaySpeed: 1 // 默认服务端上传h264数据的速度,快进或快退倍数 0:无效 1:1倍 2:2倍 3:4倍 4:8倍 5:16倍
    };
  },
  watch: {
    async deviceId() {
      // 播放设备改变
      if (this.deviceId && this.isSupported) {
        await this.closeVideo();
        this.initVideo();
      }
    },
    async codeStream() {
      //播放主子码流改变
      await this.closeVideo();
      this.initVideo();
      this.selfPlay();
    },
    currentNum() {
      // 播放通道数改变
      this.selfPause();
    },
    currentSpeed() {
      if (this.currentSpeed === 0.4) {
        this.speedLock = true;
      } else {
        this.speedLock = false;
      }
      // 播放模式改变
      this.ele.playbackRate = this.currentSpeed;
    },

    async startEnd() {
      if (
        this.deviceId &&
        this.isSupported &&
        this.startEnd &&
        this.startEnd.length
      ) {
        await this.closeVideo();
        this.initVideo();
      }
    }
  },
  mounted() {
    if (Wfs.isSupported()) {
      this.isSupported = true;
    } else {
      this.isSupported = false;
    }
    if (!this.isOpen && this.isSupported) {
      this.initVideo();
    }
  },
  methods: {
    getTimeRangesDiffer(timeRanges) {
      if (timeRanges) {
         const start = timeRanges.start(0);
         const end = timeRanges.end(0);
         return (end - start)
      }else {
        return 0
      }
    },
    getProgress() {
      const total = this.getTimeRangesDiffer(this.ele.buffered);
      const current = this.getTimeRangesDiffer(this.ele.played)
      if (!this.speedLock) {
        var differ=total - current;
        if (differ >1.0) {
          if(differ>2){
            this.ele.playbackRate = 1.5;
          }else{
            this.ele.playbackRate = 1.2;
          }

        }else if ((differ >= 0.5)&&(differ <= 1.0)) {
          this.ele.playbackRate = 1;
        } else {
          this.ele.playbackRate = 0.4;
        }
      }
    },
    loadPoster() {
      // 加载第一帧回调
    },
    closeVideo() {
      if (this.wfs) {
        // 关闭websocket
        this.wfs.websocketLoader.client.close();
        this.wfs.destroy();
        this.wfs = null;
        this.isPlay = false; // 关闭
        //只销毁websocket连接，不关闭通道
        // const result = this.closeChannel();
      }
    },

    async playVideo() {
      //点击播放回调，恢复传输
      this.ele = document.getElementById(`v${this.channelId}`);
      this.ele.playbackRate = this.currentSpeed;
      let result = "";
      if (this.mode === "realTime") {
        result = await this.openVideoChannel();
      } else {
        result = await this.openHistoryVideoChannel();
      }
      if (result) {
        //有结果返回则和设备通信成功
        this.isPlay = true;
      }
    },
    async pauseVideo() {
      // 点击暂停回调，暂停传输
      if (!this.wfs) {
        return false;
      }
      const result = await this.closeChannel();
      if (result) {
        //有结果返回则和设备通信成功
        this.isPlay = false;
      }
    },

    openVideoChannel() {
      // 请求打开视频流
      return openChannel({
        clientId: this.deviceId,
        channelId: this.channelId,
        codeType: this.codeStream,
        mediaType: 0,
        hostIP: API_VIDEOHOST,
        hostPort: API_VIDEOHOSTPORT
      });
    },
    closeChannel() {
      // 关闭音视频
      return videoControl({
        clientId: this.deviceId,
        channelId: this.channelId,
        cmdType: 0,
        closeMediaType: 0,
        changeCodeType: this.codeStream
      });
    },
    openHistoryVideoChannel() {
      //打开历史播放流
      return openHistoryChannel({
        clientId: this.deviceId,
        channelId: this.channelId,
        codeType: this.codeStream,
        mediaType: 0,
        storageType: 0,
        playType: 0, //回放方式,正常播放
        playSpeed: 0, //快进或者快退
        beginTime: this.startEnd[0],
        endTime: this.startEnd[1],
        hostIP: API_VIDEOHOST,
        hostPort: API_VIDEOHOSTPORT,
        timeout: 10
      });
    },
    replayStyleChanged() {
      //调整播放流的速度
      const dir = this.replaySpeed > 0 ? 1 : 2; // 0正常回放 1快进回放 2关键帧快退回放 3关键帧播放 4单帧上传
      return historyVideoControl({
        clientId: this.deviceId,
        channelId: this.channelId,
        playControl: dir,
        playSpeed: Math.abs(this.replaySpeed),
        playStartTime: this.startEnd[0],
        timeout: 10
      });
    },

    initVideo() {
      // 初始化websocket
      if (this.deviceId && this.isSupported) {
        this.ele = document.getElementById(`v${this.channelId}`);
        this.wfs = new Wfs();
        this.wfs.attachMedia(this.ele, `${this.extra}_${this.deviceId}_${this.channelId}`);
        if (this.isOpen) {
          this.selfPlay();
        }
      }
    },

    // 浏览器全屏适配
    full() {
      if (this.ele.requestFullscreen) {
        this.ele.requestFullscreen();
      } else if (this.ele.mozRequestFullScreen) {
        this.ele.mozRequestFullScreen();
      } else if (this.ele.webkitRequestFullscreen) {
        this.ele.webkitRequestFullscreen();
      } else if (this.ele.msRequestFullscreen) {
        this.ele.msRequestFullscreen();
      }
    },

    // 自定义按钮
    selfPlay() {
      if (this.deviceId && this.isSupported && this.ele) {
        if (this.mode === 'history') {
          if (this.startEnd && this.startEnd.length) {
            this.ele.play();
          }
        }else{
          this.ele.play();
        }
      }
    },
    selfPause() {
      if (this.deviceId) {
        this.ele.pause();
      }
    },
    fullScreen() {
      // 点击全屏
      if (this.deviceId && this.isSupported) {
        this.full();
        this.isFull = true;
      }
    },
    async fastForward() {
      //快进
      if (this.replaySpeed > 0) {
        this.replaySpeed = 2 ** (this.replaySpeed - 1);
      } else {
        this.replaySpeed = 1;
      }
      const result = await this.replayStyleChanged();
      if (result.data) {
        //通信成功，提示快进
        this.$message({
          message: `快进:X${this.replaySpeed}`
        });
      }
    },
    async rewind() {
      //快退
      if (this.replaySpeed < 0) {
        this.replaySpeed = 2 ** (this.replaySpeed - 1);
      } else {
        this.replaySpeed = -1;
      }
      const result = await this.replayStyleChanged();
      if (result.data) {
        this.$message({
          message: `快退:X${Math.abs(this.replaySpeed)}`
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.video {
  position: relative;

  .track {
    border-top: 1px solid gray;
    padding: 5px 0px 5px 0px;
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 1px;
    float: left;
    visibility: hidden;

    // display: none;
    .play {
      cursor: pointer;
      margin-left: 20px;
    }

    .fullScreen {
      float: right;
      cursor: pointer;
      margin-right: 20px;
    }

    .playMode {
      float: right;
      width: 70px;
      margin-right: 5px;
    }

    .playMode >>> .el-input--suffix .el-input__inner {
      background-color: rgba(0, 0, 0, 0);
      color: white;
    }
  }

  &:hover .track {
    visibility: visible;
  }
}
</style>
