<template>
	<el-dialog
		:visible.sync="visible"
		append-to-body
		:close-on-click-modal="false"
		@close="handleClose"
		@open="openText"
		center
		width="60%"
	>
		<el-row slot="title">
			<el-col :span="5" style="text-align:left;">一键通知</el-col>
		</el-row>
		<el-row>
			<el-col :span="11">
				<div class="top">
					突发事件等级判断
					<div>
						报警等级
						<el-select v-model="alertLevel" placeholder="报警等级">
							<el-option
								v-for="i in 10"
								:key="i"
								:label="`${i}级`"
								:value="i"
							></el-option>
						</el-select>
					</div>
					<div>
						报警类型
						<el-select v-model="alertCode" placeholder="报警类型">
							<el-option
								v-for="code in codes"
								:label="code.alertCode"
								:key="code.typeId"
								:value="code.typeId"
							></el-option>
						</el-select>
					</div>
				</div>
				<div class="foot">
					<p>通知模板</p>
					<div style="border-radius: 1px solid gray;">
						通知模板
						<el-button type="text" style="float:right;" @click="showTemplate"
							>选择模板</el-button
						>
						<el-input
							v-model="informContent"
							type="textarea"
						></el-input>
					</div>
				</div>
			</el-col>
			<el-col :span="11" style="float:right;">
				<p>根据应急响应处理指南，该突发事件响应部门和人员包括：</p>
				<el-table :data="persons">
					<el-table-column
						label="序号"
						prop="userId"
					></el-table-column>
					<el-table-column
						label="部门"
						prop="orgName"
					></el-table-column>
					<el-table-column
						label="姓名"
						prop="userName"
					></el-table-column>
					<el-table-column label="角色" prop="role"></el-table-column>
					<el-table-column
						label="联系电话"
						prop="mobile"
					></el-table-column>
					<el-table-column
						label="状态"
						prop="status"
					></el-table-column>
				</el-table>
			</el-col>
		</el-row>
		<el-row style="margin-top:20px;">
			<el-button type="primary" @click="dispathNotice"
				>下发通知</el-button
			>
			<el-button>取消</el-button>
		</el-row>
		<textMessageDialog
			:show="showTextMessage"
			@close="closeDialog"
		></textMessageDialog>
	</el-dialog>
</template>

<script>
import textMessageDialog from "@/components/textDispatch/selectText";
import { queryTextMessage } from "@/api/map/textMessage";
import { handleAlert, queryOrgDept } from "@/api/alarm/intelligentEWP";
import { alertType } from "@/api/alarm/alarmType";
import _ from "loadsh";
export default {
	name: "oneKeyNotice",
	props: {
		show: Boolean,
		driverIds: String,
		alarmId: String,
	},
	data() {
		return {
			visible: false,
			showTextMessage: false,
			textSend: {
				value: "default",
			},
			sendContent: "",
			modelContent: "",
			textList: [],
			checkList: [],

			alertLevel: "",
			alertCode: "",
			informContent: "",

			codes: [],

			persons: [],
		};
	},
	watch: {
		show: {
			handler() {
				this.visible = this.show;
			},
			immediate: true,
		},
		alertCode() {
			if (this.alertCode) {
				queryOrgDept({ typeId: this.alertCode }).then((res) => {
					this.persons = res.data.detail;
				});
			}
		},
	},
	methods: {
		openText() {
			this.initMessages();
			this.initAlertType();
		},
		msgModel() {
			this.showTextMessage = true;
		},
		initAlertType() {
			alertType().then((res) => {
				this.codes = res.data.detail.list;
			});
		},
		initMessages() {
			queryTextMessage().then((res) => {
				this.textList = res.data.detail.list;
			});
		},
		reset() {
			this.sendContent = "";
			this.modelContent = "";
			this.alertLevel = "";
			this.alertCode = "";
			this.informContent = "";
		},
		handleClose() {
			this.reset();
			this.$emit("closeDialog");
		},
		textChange(value) {
			this.sendContent = value;
		},
		cancelSend() {
			this.handleClose();
		},
		sendOut() {
			if (_.isEmpty(this.deviceId)) {
				this.$message({
					message: "设备未绑定",
					showClose: true,
				});
				return;
			}
			if (!this.sendContent) {
				this.$message({
					message: "请输入下发文本",
					showClose: true,
				});
				return;
			}
		},
		closeDialog() {
			this.initMessages();
			this.showTextMessage = false;
		},
		async dispathNotice() {
			await handleAlert({
				alertLevel: this.alertLevel,
				alertCode: this.alertCode,
				informContent: this.informContent,
				id: this.alarmId,
			});
			this.$message.success({
				message: "一键通知成功",
				showClose: true,
			});
			this.handleClose();
        },
        showTemplate() {
            this.showTextMessage = true
        }
	},
	components: {
		textMessageDialog,
	},
};
</script>

<style lang="scss" scoped>
.top {
	> div {
		margin-top: 20px;
		margin-left: 20px;
	}
}
</style>
