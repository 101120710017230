import _ from 'lodash';
import './request';
//修改默认el-date-picker非格式化下的8个时区问题
Date.prototype.$Fixed = function() {
  // 修复时区问题
  if (this instanceof Date) {
    //时区对象
    return new Date(this.setHours(this.getHours() + 8))
  } else {
    return this
  }
}
// 日期格式化 
Date.prototype.$Formater = function(fmt) {
  if (fmt) {
    return formatDate(this, fmt)
  } else {
    return this
  }
}

Date.prototype.$getAddDate = function(AddDayCount) {
  this.setDate(this.getDate() + AddDayCount) //获取AddDayCount天后的日期  
  return this
}

String.prototype.$FixedZeroTime = function () {
    var reg = /^(?:19|20)[0-9][0-9]-(?:(?:0[1-9])|(?:1[0-2]))-(?:(?:[0-2][1-9])|(?:[1-3][0-1])) (?:(?:[0-2][0-3])|(?:[0-1][0-9])):[0-5][0-9]:[0-5][0-9]$/;
    if (this && reg.test(this)) {
        const dateArr = this.split(" ")
        return dateArr[0] + " 23:59:59"
    }else{
        return new Date().Format("yyyy-MM-dd hh:mm:ss")
    }
};

String.prototype.$FixedMacTime = function() {
    if (this && this.length === 12) {
      const year = `20${this.substr(0, 2)}`;
      const month = this.substr(2, 2);
      const day = this.substr(4, 2);
      const hour = this.substr(6, 2);
      const minute = this.substr(8, 2);
      const second = this.substr(10, 2);
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    } else if (this && this.length === 6) {
      const year = `20${this.substr(0, 2)}`;
      const month = this.substr(2, 2);
      const day = this.substr(4, 2);
      return `${year}-${month}-${day}`;
    } else {
      return this;
    }
  };

function formatDate(date, fmt) {
  if (!_.isDate(date)) {
    date = new Date(date)
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
    }
  }
  return fmt
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}


// Vue.prototype.$http = axios