import Vue from 'vue'
import Vuex from 'vuex'
import {removeToken} from "@/utils/token"
import Util from '@/common/js/util.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    isOneKey: false,  // 一键报警窗口是否已经弹出
    accessUrls: []
  },
  getters: {
    userInfo: state => state.userInfo,
    isOneKey: state => state.isOneKey,
    accessUrls: state => state.accessUrls
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_USERINFO(state, info) {
      state.userInfo = info
    },
    SET_ISONEKEY(state, status) {
      state.isOneKey = status
    },
    SET_ACCESSURLS(state,urls) {
        state.accessUrls = urls
      }
  },
  actions: {
    setUser({commit}) {
        return new Promise((resolve) => {
          Vue.prototype.$http({
              url: "/system/user/loggedInUser"
          }).then(res => {
            commit('SET_USERINFO', res.detail)
            Util.setStor('emergencyAdminInfo',res.detail)
            resolve()
          }).catch(err => {
              console.error(err)
            // reject(err)
          })
        })
     },
    logout() {
        //移除token 刷新界面
        removeToken();
        location.reload()
    },
    AlertOneKeyStatus({commit},status) {
       commit('SET_ISONEKEY',status)
    },
    setUrls({commit},urls) {
        commit('SET_ACCESSURLS',urls)
      }
  },
  modules: {
  }
})
