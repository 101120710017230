<template>
<el-dialog title="添加消息模板" :visible.sync="msgBox" width="500px" append-to-body @close="hanldeClose" :modal-append-to-body="false">
  <el-select v-model="msgType" placeholder="选择模板类型" size="mini" style="width:100%;margin-bottom:5px;">
    <el-option v-for="option in options" :key="option.type" :label="option.label" :value="option.type"></el-option>
  </el-select>
  <el-input style="width:100%;margin-bottom:5px;" type="textarea" rows="2" v-model.trim="content" placeholder="请输入消息模板" size="small"></el-input>
  <div class="btngroup">
    <el-button class="addBtn" @click="createMsg" type="primary" size="small">添加模板</el-button>
  </div>
</el-dialog>
</template>

<script>
import { saveText } from '@/api/map/textMessage'
 export default {
   name: 'textMessage',
   props: {
       show: Boolean
   },
   methods: {
    async createMsg () {
        if (!this.msgType) {
             this.$message({
                message: '请选择模板类型',
                showClose: true,
                type: 'warning'
            })
            return
        }
        if (!this.content) {
             this.$message({
                message: '请填写内容',
                showClose: true,
                type: 'warning'
            })
            return
        }
        await saveText(this.content, this.msgType).then(res => {
           this.$message({
                message: res.data.msg,
                showClose: true,
                type: 'success'
            })
        })
    },
    hanldeClose () {
      this.resetDialog()
      this.$emit('close')
    },
    resetDialog() {
      this.msgType = ''
      this.content = ''
    }
   },
   data () {
     return {
      msgBox: this.show,
      content: '',
      msgType: '',
      options: [{
        type: '11',
        label: '打哈欠'
      },{
        type: '12',
        label: '抽烟'
      },{
        type: '13',
        label: '闭眼'
      },{
        type: '14',
        label: '车道偏离'
      },{
        type: '15',
        label: '打手机'
      },{
        type: '16',
        label: '视线偏离'
      },{
        type: '17',
        label: '前向碰撞'
      },{
        type: '20',
        label: '三电数据'
      }]
     }
   },
   watch: {
     show () {
         this.msgBox = this.show
     }
   },
   components: {

   }
 }
</script>

<style lang="scss" scoped>
.btngroup{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  .addBtn{
    width: 150px;
  }
}
</style>
