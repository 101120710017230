<template>
	<el-dialog
		title="一键报警"
		:visible.sync="visible"
		width="70%"
		append-to-body
		@open="openDialog"
		@close="handleClose"
	>
		<div id="bigAlertMap"></div>
	</el-dialog>
</template>

<script>
import _ from "lodash";
import BMap from "BMap";
export default {
	name: "mapLoc",
	props: {
		vehicleInfo: Object,
		show: Boolean,
	},
	data() {
		return {
			visible: false,
		};
	},
	watch: {
		show: {
			handler() {
				this.visible = this.show;
			},
			immediate: true,
		},
	},
	methods: {
		openDialog() {
			this.$nextTick(() => {
				this.initMap();
			});
		},
		handleClose() {
			this.$emit("close");
		},
		async initMap() {
			if (_.isEmpty(this.vehicleInfo)) {
				return;
			}
			var map = new BMap.Map("bigAlertMap");
			const latLng = this.vehicleInfo.latLng;
			var point = new BMap.Point(latLng.lng, latLng.lat);
			map.centerAndZoom(point, 15);

			var icon = new BMap.Icon(
				require("../../assets/operate/alert.png"),
				new BMap.Size(50, 50)
			);

			var marker = new BMap.Marker(point, { icon }); // 创建标注
			map.addOverlay(marker); // 将标注添加到地图中
			var top_left_navigation = new BMap.NavigationControl(); // 左上角，添加默认缩放平移控件
			map.addControl(top_left_navigation);
			map.enableScrollWheelZoom(true);
			var opts = {
				width: 200, // 信息窗口宽度
				height: 100, // 信息窗口高度
				enableMessage: true, // 设置允许信息窗发送短息
			};
			var html = "";
			if (this.vehicleInfo) {
				html += "<div>车牌：" + this.vehicleInfo.plateNo + "</div>";
				html +=
					"<div>速度：" +
					this.vehicleInfo.currentSpeed +
					"km/h</div>";
				html +=
					"<div>时间：" +
					this.vehicleInfo.gatherTime.$FixedMacTime() +
					"</div>";
			}
            // const result = await transFormLatLng(point.lat, point.lng);
            const result = this.$http({
					baseURL: "/baidu",
					url: "/geocoder",
					method: "get",
					data: {
						location: `${point.lat},${point.lng}`,
						output: "json",
						key: VUE_BAIDU_MAP_AK,
					},
				})
			html +=
				"<div>地址：" + result.data.result.formatted_address + "</div>";
			var infoWindow = new BMap.InfoWindow(html, opts);
			/* eslint-enable */
			setTimeout(function() {
				map.openInfoWindow(infoWindow, point);
			}, 600);
		},
	},
};
</script>

<style lang="scss" scoped>
#bigAlertMap {
	height: 600px;
	width: 100%;
}
</style>
