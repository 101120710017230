<template>
  <div id="app">
    <page-top
      :noTopRoute="noTopRoute"
      :logo="getLogo"
      @logout="logout"
      :rightText="getUserName"
    ></page-top>
    <keep-alive>
      <router-view />
    </keep-alive>

    <oneKeyAlert
      :show="oneKeyAlertShow"
      :alarmTime="alarmTime"
      :clientId="clientId"
      :dvrId="dvrId"
    ></oneKeyAlert>
  </div>
</template>
<script>
import pageTop from "@/components/jyui/components/top";
import logo from "@/assets/logo.png";
import { API_WEBSOCKET, API_ALERT_SOCKET } from "@/config/env";
import oneKeyAlert from "@/components/oneKeyAlert";
export default {
  data() {
    return {
      noTopRoute: ["login"],

      websocket: null,

      dvrId: "",
      oneKeyAlertShow: false,
      clientId: "",
      alarmTime: "",
    };
  },
  mounted() {
    this.initWebsocket();
  },
  components: {
    pageTop,
    oneKeyAlert,
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    initWebsocket() {
      const vm = this;
      if (this.$util.getStor("token")) {
        const wsUrl = API_WEBSOCKET + API_ALERT_SOCKET + this.$util.getStor("token");
        this.websocket = new WebSocket(wsUrl);
        this.websocket.onopen = function() {};
        this.websocket.onmessage = function(res) {
          const jsonData = JSON.parse(res.data);
          if (jsonData.msg === "photo" && jsonData.detail) {
            vm.imageUrl = jsonData.detail;
          }
          if (jsonData.msg === "oneKeyAlert" && jsonData.detail) {
            if (!vm.oneKeyAlertShow) {
              vm.clientId = jsonData.detail.clientId;
              vm.alarmTime = jsonData.detail.data.gatherTime;
              vm.dvrId = jsonData.dvrDevice;
            }
            vm.oneKeyAlertShow = true;
          }
        };
        this.websocket.onclose = function() {
          console.log("websocket close");
        };
        this.websocket.onerror = function() {
          vm.$message.error({
            message: "websocket连接失败",
            duration: 3000,
          });
        };
      }
    },
  },
  computed: {
    getUserName() {
      const userInfo = this.$store.state.userInfo;
      return userInfo.realName || userInfo.userName;
    },
    getLogo() {
      var userInfo = this.$store.state.userInfo || {};
      if(userInfo&&userInfo.userId){
        return userInfo.logoImg || logo
      }else{
        userInfo = this.$util.getStor('emergencyAdminInfo') || userInfo
      }
      return userInfo.logoImg;
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
