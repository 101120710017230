<template>
  <div class="videoPlayer">
    <videoPlayer
      v-for="channelId in 9"
      ref="video"
      :key="channelId"
      class="v9 vCommon"
      :isOpen="true"
      :currentNum="9"
      :channelId="`${channelId}`"
      :deviceId="dvrId"
      preload="none"
    ></videoPlayer>
  </div>
</template>

<script>
import videoPlayer from "@/components/videoPlayer";
export default {
  name: "videoMonitor",
  props: {
      dvrId: String
  },
  components: {
    videoPlayer
  },
  methods: {
    initVideo() {
      for (let i = 0; i < 9; i++) {
          if (this.$refs.video[i]) {
            this.$refs.video[i].initVideo()
          }
      }
    },
    destroyVideo() {
      for (let i = 0; i < 9; i++) {
          if (this.$refs.video[i]) {
            this.$refs.video[i].closeVideo()
          }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.videoPlayer {
    height: 100%;
    margin-left: 400px;

  .vCommon {
    float: left;
    padding: 1px;
  }

  .v9 {
    width: 32%;
    height: 33%;
  }
}
</style>
