<script>
/**
 * npm i babel-plugin-jsx-v-model -D
 * 支持v-model
 * npm i loadsh -S
 * 安装loadsh
 * 暂时只支持单个图片上传
 */
/* eslint-disable */

import _ from "loadsh";
// import style from './cstyle.scss';
export default {
    name: "cdialog",
    props: {
        columns: Array,
        isEdit: Boolean, //是否编辑状态
        isView: Boolean, // 是否查看状态
        title: String,
        show: Boolean,
        width: String,
        max_height: String, //el-form的高度
        data: Object,
        labelWidth: {
            type: String,
            default: "100px"
        },
        showDouble: Boolean,

        inputLimit: { // 输入框输入文字限制
            type: Number,
            default: 50
        }
    },
    render() {
        return (
            <el-dialog
                title={this.getTitle}
                width={this.width}
                visible={this.show}
                append-to-body
                on-open={this.openDialog}
                on-close={this.handleClose}
                class="c_dialog"
            >
                <el-row>
                    <el-form
                        ref="formRef"
                        {...{
                            props: { model: this.form, disabled: this.isView }
                        }}
                        label-width={this.labelWidth}
                    >
                        {this.realColumns.map(item => {
                            return (
                                <el-col
                                    span={
                                        item.span ? item.span : this.realWidth
                                    }
                                >
                                    <el-form-item
                                        label={item.title}
                                        prop={item.prop}
                                        rules={item.rules}
                                    >
                                        {this.initFormItem(item)}
                                    </el-form-item>
                                </el-col>
                            );
                        })}
                        <el-col span={24} v-show={!this.isView}>
                            <el-form-item>
                                <el-button
                                    size="small"
                                    type="primary"
                                    on-click={this.confirm}
                                >
                                    确定
                                </el-button>
                                <el-button
                                    size="small"
                                    type="plain"
                                    on-click={this.handleClose}
                                >
                                    取消
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </el-row>
            </el-dialog>
        );
    },
    data() {
        return {
            form: {},
            picobj: null, // logo的所有属性值

            checkInputLength: (rule, value, cb) => {
                if (!value) {
                    cb();
                } else if (value.toString().length > this.inputLimit) {
                    cb(new Error(`输入不能超过${this.inputLimit}个字符`));
                } else {
                    cb();
                }
            }
        };
    },
    watch: {
        data: {
            handler() {
                if (this.data) {
                    this.form = _.cloneDeep(this.data);
                } else {
                    this.form = {};
                }
            },
            immediate: true
        },
        computedForm: {
            handler(n, o) {
                const keys = Object.keys(this.form);
                if (keys && keys.length) {
                    keys.forEach(v => {
                        if (!_.isEqual(n[v], o[v])) {
                            this.$emit("watch", {
                                key: v,
                                n: n[v],
                                o: o[v]
                            });
                            return;
                        }
                    });
                }
            },
            deep: true
        }
    },
    computed: {
        realColumns() {
            let list = [];
            list = _.compact(
                this.columns.map(v => {
                    if (v.tag) {
                        return {
                            ...v,
                            attrs: this.initAttrs(v),
                            rules: this.initRules(v)
                            // slots: this.initSlots(v),
                            // rules: this.initRules(v),
                            // props: this.initProps(v),
                        };
                    }
                })
            );
            return list;
        },
        realWidth() {
            if (this.showDouble) {
                return 12;
            } else {
                return 24;
            }
        },
        getTitle() {
            if (this.isView) {
                return `查看${this.title}`;
            } else if (this.isEdit) {
                return `编辑${this.title}`;
            } else {
                return `新增${this.title}`;
            }
        },
        computedForm() {
            return _.cloneDeep(this.form);
        }
    },
    methods: {
        handleClose() {
            this.resetForm();
            this.$emit("close");
        },
        openDialog() {
            //去掉之前的校验结果
            this.$nextTick(() => {
                this.$refs.formRef.clearValidate();
            });
            // 初始化需要初始化的data,例如el-checkbox-group
            this.initFormData();
        },
        resetForm() {
            // this.$refs.formRef.resetFields()
            this.form = {};
            if (this.picobj && this.picobj.ref && this.$refs[this.picobj.ref]) {
                this.$refs[this.picobj.ref].clearFiles();
            }
            this.picobj = {};
        },
        initFormData() {
            this.columns.forEach(v => {
                if (v.tag === "el-checkbox-group") {
                    this.$set(this.form, v.prop, new Array());
                }
            });
        },
        confirm() {
            this.$refs.formRef.validate(valide => {
                if (valide) {
                    this.$emit("verify", this.form);
                }
            });
        },
        initSlots(v) {
            //若标签之间还有其他元素，则做初始化
            if (v.tag === "el-select") {
                //选择框
                if (v.options && v.options.length) {
                    const name = v.dfProps ? v.dfProps.name : "name";
                    const value = v.dfProps ? v.dfProps.value : "value";
                    return v.options.map(item => {
                        return (
                            <el-option
                                label={item[name]}
                                value={item[value]}
                            ></el-option>
                        );
                    });
                }
            } else if (v.tag === "el-upload") {
                //logo
                this.picobj = v;
                if (this.form[v.prop]) {
                    return <img src={this.form[v.prop]} class="avatar"></img>;
                } else {
                    return (
                        <i
                            class="el-icon-plus avatar-uploader-icon"
                            style="border: 1px dashed #d9d9d9"
                        ></i>
                    );
                }
            } else if (v.tag === "el-radio-group") {
                // 单选框
                if (v.options && v.options.length) {
                    const name = v.dfProps ? v.dfProps.name : "name";
                    const value = v.dfProps ? v.dfProps.value : "value";
                    return v.options.map(item => {
                        return (
                            <el-radio label={item[value]}>
                                {item[name]}
                            </el-radio>
                        );
                    });
                }
            } else if (v.tag === "el-checkbox-group") {
                //多选框
                if (v.options && v.options.length) {
                    const name = v.dfProps ? v.dfProps.name : "name";
                    const value = v.dfProps ? v.dfProps.value : "value";
                    return v.options.map(item => {
                        return (
                            <el-checkbox label={item[value]}>
                                {item[name]}
                            </el-checkbox>
                        );
                    });
                }
            }
        },
        initRules(v) {
            //初始化规则，设置默认规则
            if (v.rules) {
                return v.rules;
            }
            if (v.unNeed) {
                if (v.tag === "el-input") {
                    return [
                        { validator: this.checkInputLength, trigger: "blur" }
                    ];
                } else {
                    return null;
                }
            } else {
                if (v.tag === "el-input") {
                    return [
                        {
                            required: true,
                            message: `${v.title}不能为空`,
                            trigger: "blur"
                        },
                        { validator: this.checkInputLength, trigger: "blur" }
                    ];
                } else {
                    return [
                        {
                            required: true,
                            message: `${v.title}不能为空`,
                            trigger: "change"
                        }
                    ];
                }
            }
        },
        initAttrs(v) {
            //标签设置默认属性
            if (v.tag === "el-input") {
                return {
                    size: "small",
                    ...v.attrs
                };
            } else if (v.tag === "el-upload") {
                return {
                    ...v.attrs
                };
            }
        },
        initProps(v) {
            if (v.tag === "el-upload") {
                return {
                    ...v.props,
                    accept: ".jpg,.png,.jpeg",
                    onSuccess: this.handleAvatarSuccess,
                    beforeUpload: this.beforeAvatarUpload
                };
            }
            return v.props;
        },
        initFormItem(item) {
            if (item.tag === "slot") {
                return this.$slots[item.prop];
            } else if (item.tip) {
                return (
                    <el-row>
                        <item.tag
                            {...{
                                attrs: this.initAttrs(item),
                                props: this.initProps(item),
                                style: { width: "100%", ...item.style }
                            }}
                            v-model={this.form[item.prop]}
                        >
                            {this.initSlots(item)}
                        </item.tag>
                        <p style="color:red;">{item.tip}</p>
                    </el-row>
                );
            } else {
                return (
                    <item.tag
                        {...{
                            attrs: this.initAttrs(item),
                            props: this.initProps(item),
                            style: { width: "100%", ...item.style }
                        }}
                        v-model={this.form[item.prop]}
                    >
                        {this.initSlots(item)}
                    </item.tag>
                );
            }
        },
        handleAvatarSuccess(res) {
            //保存id及url
            if (res.detail) {
                this.$set(this.form, this.picobj.prop, res.detail.url);
                if (this.picobj.fileKey) {
                    this.form[this.picobj.fileKey] = res.detail.id;
                }
            }
            this.$emit("upSuccess", res);
            // this
        },
        beforeAvatarUpload(file) {
            const _this = this;
            const limitSize = this.picobj.size ? this.picobj.size : 5;
            return new Promise(function(resolve, reject) {
                var reader = new FileReader();
                reader.onload = function(event) {
                    if (
                        !["image/jpeg", "image/png", "image/jpg"].includes(
                            file.type
                        )
                    ) {
                        _this.$message.warning({
                            message: "上传头像图片只能是 JPG JPEG PNG 格式!",
                            duration: 3000
                        });
                        reject();
                    }
                    if (file.size / 1024 / 1024 >= limitSize) {
                        _this.$message.warning({
                            message: `上传图片大小不能大于${limitSize}MB!`,
                            duration: 3000
                        });
                        reject();
                    }
                    var image = new Image();
                    image.onload = function() {
                        var width = this.width;
                        var height = this.height;
                        if (_this.picobj.wh) {
                            const wh = _this.picobj.wh;
                            if (width > wh.width) {
                                _this.$message.warning({
                                    message: `上传头像图片宽度不能大于${wh.width}px!`,
                                    duration: 3000
                                });
                                reject();
                            } else if (height > wh.height) {
                                _this.$message.warning({
                                    message: `上传头像图片高度不能大于${wh.height}px!`,
                                    duration: 3000
                                });
                                reject();
                            } else {
                                resolve();
                            }
                        } else {
                            resolve();
                        }
                    };
                    image.src = event.target.result;
                };
                reader.readAsDataURL(file);
            });
        },
        getFormData() {
            return this.form;
        }
    }
};
</script>
<style lang="scss" scoped>
@import "./cstyle.scss";
</style>
<style type="text/css" lang="scss">
.c_dialog {
    .el-row {
        .el-col-12 {
            min-height: 63px;
        }
    }
    .el-upload-list--text {
        .el-upload-list__item-status-label,
        .el-upload-list__item-name {
            display: none;
        }
    }
}
</style>
