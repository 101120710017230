
import _ from 'lodash'
export function formatDate(date, fmt) {
  if (!_.isDate(date)) {
    date = new Date(date)
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
    }
  }
  return fmt
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}
/**
 * 获取年的第N个季度的开始时间
 * @param {String} year
 * @param {Number} n
 * @param {String} fmt
 */
export function getQuarterStartMonth(year, n, fmt) {
  var quarterStartDate = new Date(year, getQuarterStartMonthByN(n), 1)
  return formatDate(quarterStartDate, fmt)
}

function getQuarterStartMonthByN(n) {
  var quarterStartMonth = 0
  switch (n) {
    case 1:
      quarterStartMonth = 0
      break
    case 2:
      quarterStartMonth = 3
      break
    case 3:
      quarterStartMonth = 6
      break
    case 4:
      quarterStartMonth = 9
      break
  }
  return quarterStartMonth
}
/**
 * 获取年的第N个季度的结束时间
 * @param {String} year
 * @param {Number} n
 * @param {String} fmt
 */
export function getQuarterEndMonth(year, n, fmt) {
  var quarterEndMonth = getQuarterStartMonthByN(n) + 2
  var quarterStartDate = new Date(year, quarterEndMonth, getMonthDays(year, quarterEndMonth))
  return formatDate(quarterStartDate, fmt)
}

// 获得某月的天数
function getMonthDays(y, m) {
  var monthStartDate = new Date(y, m, 1)
  var monthEndDate = new Date(y, m + 1, 1)
  var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24)
  return days
}

/**
 * 将时长转换为时长字符串
 * @param {int} StatusMinute
 */
export function timeStampByMinute(StatusMinute) {
  var day = parseInt(StatusMinute / 60 / 24)
  var hour = parseInt(StatusMinute / 60 % 24)
  var min = parseInt(StatusMinute % 60)
  StatusMinute = ''
  if (day > 0) {
    StatusMinute = day + '天'
  }
  if (hour > 0) {
    StatusMinute += hour + '小时'
  }
  if (min > 0) {
    StatusMinute += parseFloat(min) + '分钟'
  }
  return StatusMinute
}
/**
 * 将时长转换为时长字符串
 * @param {int} StatusMinute
 */

export function timeStamp (miliSec) {
  var day = parseInt(miliSec / 1000 / 60 / 60 / 24)
  var hour = parseInt(miliSec / 1000 / 60 / 60 % 24)
  var min = parseInt(miliSec / 1000 / 60 % 60)
  var sec = parseInt(miliSec / 1000 % 60)
  miliSec = ''
  if (day > 0) {
    miliSec = day + '天'
  }
  if (hour > 0) {
    miliSec += hour + '小时'
  }
  if (min > 0) {
    miliSec += parseFloat(min) + '分钟'
  }
  if (sec > 0) {
    miliSec += sec + '秒'
  }
  return miliSec
}

/**
 * 字符串转时间戳
 */
export function getTimestamp(dateStr) {
  dateStr = dateStr.replace(/-/g,'/');
  return new Date(dateStr).getTime();
}
/**
 * @description 格式化日期对象 到时分秒 00:00:00 或者 23:59:59 end为真格式化到23:59:59 否则00:00:00
 * add by lishibin 2018-11-24
 * @param {Date} date
 * @param {any} end
 */
export function initDate(date, end, formatter) {
  if (!_.isDate(date)) {
    date = new Date()
  }
  if (!formatter) {
    formatter = 'yyyy-MM-dd hh:mm:ss'
  }
  const tempDate = _.cloneDeep(date)
  tempDate.setHours(end ? 23 : 0)
  tempDate.setMinutes(end ? 59 : 0)
  tempDate.setSeconds(end ? 59 : 0)
  return formatDate(tempDate, formatter)
}

export function getStartEnd() {
  const date = new Date()
  date.setDate(date.getDate() - 7)
  return [initDate(date), initDate(new Date(), true)]
}

/**
 * 百分比(保留二位小数)
 */
export function toPercent(date) {
  var str = 0
  if (date === 1 || date === 0) {
    str = date * 100
  } else {
    str = (date * 100).toFixed(2)
  }
  return str + '%'
}
/**
 * 190812203354 转为 2019-08-12 20:35:54
 * @param {} dateStr 
 */
export function tranformDate (dateStr) {
  if (dateStr && dateStr.length === 12) {
    const year = `20${dateStr.substr(0, 2)}`
    const month = dateStr.substr(2, 2)
    const day = dateStr.substr(4, 2)

    const hour = dateStr.substr(6, 2)
    const minute = dateStr.substr(8, 2)
    const second = dateStr.substr(10, 2)
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`
  }else if(dateStr && dateStr.length === 6){
    const year = `20${dateStr.substr(0, 2)}`
    const month = dateStr.substr(2, 2)
    const day = dateStr.substr(4, 2)
    return `${year}-${month}-${day}`
  }else{
    return dateStr
  }
}

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) {
        time = parseInt(time) * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') {
        return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}
