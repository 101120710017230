<template>
  <div id="alertMap"></div>
</template>

<script>
import { transFormLatLng } from "@/api/map/baiduAPI";
import { tranformDate } from "@/utils/date";
import _ from "lodash";
import BMap from 'BMap';
export default {
  name: "mapLoc",
  props: {
    vehicleInfo: Object
  },
  data() {
    return {};
  },
  watch: {
    vehicleInfo() {
      if (!_.isEmpty(this.vehicleInfo)) {
        this.initMap();
      }
    }
  },
  mounted() {
    if (!_.isEmpty(this.vehicleInfo)) {
      this.initMap();
    }
  },
  methods: {
    async initMap() {
      var map = new BMap.Map("alertMap");
      const latLng = this.vehicleInfo.latLng;
      var point = new BMap.Point(latLng.lng, latLng.lat);
      map.centerAndZoom(point, 15);

      var icon = new BMap.Icon(
        require("../../assets/operate/alert.png"),
        new BMap.Size(50, 50)
      );

      var marker = new BMap.Marker(point, { icon }); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
      var top_left_navigation = new BMap.NavigationControl(); // 左上角，添加默认缩放平移控件
      map.addControl(top_left_navigation);
      map.enableScrollWheelZoom(true);
      var opts = {
        width: 200, // 信息窗口宽度
        height: 100, // 信息窗口高度
        enableMessage: true // 设置允许信息窗发送短息
      };
      var html = "";
      if (this.vehicleInfo) {
        html += "<div>车牌：" + this.vehicleInfo.plateNo + "</div>";
        html += "<div>速度：" + this.vehicleInfo.currentSpeed + "km/h</div>";
        html +=
          "<div>时间：" + tranformDate(this.vehicleInfo.gatherTime) + "</div>";
      }
      const result = await transFormLatLng(point.lat, point.lng);
      html += "<div>地址：" + result.data.result.formatted_address + "</div>";
      var infoWindow = new BMap.InfoWindow(html, opts);
      /* eslint-enable */
      setTimeout(function() {
        map.openInfoWindow(infoWindow, point);
      }, 600);
    }
  }
};
</script>

<style lang="scss" scoped>
#alertMap {
  height: 280px;
  width: 400px;
}
</style>
