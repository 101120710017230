import qs from "qs";
import Vue from 'vue';
import axios from "axios";
import {getToken} from "@/utils/token"
import { API_BASEURL, BASE_JSON } from "@/config/env";
import { Message, MessageBox } from 'element-ui';
import store from "@/store/index";

Vue.prototype.$http = ({
  url,
  data,
  blob,
  baseURL,
  method = "post",
  timeout = 3000,
  isForm = false,
  allRes = false,
}) => {
  let headers, transformRequest, responseType,name;
  if (!BASE_JSON || isForm) {
    headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    transformRequest = [(data) => qs.stringify(data)];
  }
  if (blob) {
    responseType = "blob";
  }
  if (method == 'post') {
      name = 'data'
  }else{
      name = 'params'
  }
  const request = axios.create({
    baseURL: baseURL || API_BASEURL,
    headers,
    transformRequest,
  });

  request.interceptors.request.use(
    (config) => {
        if (getToken()) {
            config.headers.token = getToken();
        }
      return config;
    },
    (err) => {
      console.log(err);
    }
  );

  request.interceptors.response.use(
    (response) => {
      const data = response.data;
      if (data.code !== "0" && data.code) {
        // 判断token失效
        if (data.code === "401") {
          MessageBox.confirm(
            "你的登录认证失效，请重新登录",
            "确定登出",
            {
              confirmButtonText: "重新登录",
              cancelButtonText: "取消",
              type: "warning",
            }
          ).then(() => {
            store.dispatch("logout")
          });
        } else if (data.code === "-1") {
          // 请求报错显示
          Message({
            message: data.error,
            showClose: true,
            type: "error",
          });
          // return response
          return Promise.reject(data.error);
        }
      } else {
        // 释放拦截的响应
        if (allRes) {
            return Promise.resolve(response);
        }else{
            return Promise.resolve(data);
        }
      }
    },
    (error) => {
      Message({
        message: error.message,
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(error);
    }
  );

  return request({
    url,
    method,
    timeout,
    [name]: data,
    responseType
  });
};
