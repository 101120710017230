import tokenHelper from 'js-cookie'
const tokenKey = 'zyToken'

export function setToken (token) {
  return tokenHelper.set(tokenKey, token)
}

export function getToken () {
  return tokenHelper.get(tokenKey)
}

export function removeToken () {
  return tokenHelper.remove(tokenKey)
}
