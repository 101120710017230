<template>
	<el-dialog
		:visible.sync="visible"
		modal-append-to-body
		append-to-body
		title="报警数据"
		:close-on-click-modal="false"
		@close="handleClose"
		width="760px"
		height="732px"
	>
		<el-tabs
			type="border-card"
			v-model="activeCode"
			@tab-click="openPlan"
			stretch
		>
			<el-tab-pane label="三电数据" name="1">
				<div class="tab-box tab-box-border">
					<el-tabs v-model="activeElecTab" stretch>
						<el-tab-pane
							label="整车数据"
							name="ovrallData"
							style="margin-top:-10px;"
						>
							<div class="electricBox" v-if="overallVehData">
								<el-row>
									<el-col :span="24">
										<p class="ovrallDataText">
											<span class="ovrallDataTitle"
												>车辆状态：</span
											>
											{{ overallVehData.powerStatus }}
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="ovrallDataText">
											<span class="ovrallDataTitle"
												>充电状态：</span
											>
											{{ overallVehData.chargeStatus }}
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="ovrallDataText">
											<span class="ovrallDataTitle"
												>运行模式：</span
											>
											{{ overallVehData.runningStatus }}
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="ovrallDataText">
											<span class="ovrallDataTitle"
												>车速：</span
											>
											{{
												overallVehData.currentSpeed
											}}km/h
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="ovrallDataText">
											<span class="ovrallDataTitle"
												>总里程：</span
											>
											{{
												overallVehData.currentMileage
											}}km
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="ovrallDataText">
											<span class="ovrallDataTitle"
												>总电压：</span
											>
											{{ overallVehData.totalVoltage }}V
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="ovrallDataText">
											<span class="ovrallDataTitle"
												>总电流：</span
											>
											{{ overallVehData.totalCurrent }}A
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="ovrallDataText">
											<span class="ovrallDataTitle"
												>剩余电量：</span
											>
											{{ overallVehData.socStatus }}%
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="ovrallDataText">
											<span class="ovrallDataTitle"
												>DC-DC状态：</span
											>
											{{ overallVehData.dcStatus }}
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="ovrallDataText">
											<span class="ovrallDataTitle"
												>档位：</span
											>
											{{ overallVehData.gearStatus }}
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="ovrallDataText">
											<span class="ovrallDataTitle"
												>绝缘电阻：</span
											>
											{{ overallVehData.resistance }}kΩ
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="ovrallDataText">
											<span class="ovrallDataTitle"
												>加速踏板：</span
											>
											{{ overallVehData.accelValue }}%
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="ovrallDataText">
											<span class="ovrallDataTitle"
												>制动踏板：</span
											>
											{{ overallVehData.brakeValue }}%
										</p>
									</el-col>
								</el-row>
							</div>
						</el-tab-pane>
						<el-tab-pane
							name="2"
							label="电机数据"
							style="margin-top:-10px;"
						>
							<div
								v-if="driveMotorData && driveMotorData.length"
								class="electricBox"
							>
								<el-row>
									<el-col :span="24">
										<p class="electricText">
											<span class="electricTitle"
												>电驱个数：</span
											>
											{{ driveMotorData.length }}个
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="electricText">
											<span class="electricTitle"
												>驱动电机状态：</span
											>
											{{ driveMotorData[0].driveStatus }}
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="electricText">
											<span class="electricTitle"
												>驱动电机控制温度：</span
											>
											{{ driveMotorData[0].ctrlTemp }}℃
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="electricText">
											<span class="electricTitle"
												>驱动电机转速：</span
											>
											{{
												driveMotorData[0].driveSpeed
											}}r/min
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="electricText">
											<span class="electricTitle"
												>驱动电机转矩：</span
											>
											{{
												driveMotorData[0].driveTorque
											}}N/m
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="electricText">
											<span class="electricTitle"
												>驱动电机温度：</span
											>
											{{ driveMotorData[0].driveTemp }}℃
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="electricText">
											<span class="electricTitle"
												>电机控制器输入电压：</span
											>
											{{ driveMotorData[0].busVoltage }}V
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="electricText">
											<span class="electricTitle"
												>电机控制器直流母线电流：</span
											>
											{{ driveMotorData[0].busCurrent }}A
										</p>
									</el-col>
								</el-row>
							</div>
						</el-tab-pane>
						<el-tab-pane
							name="ele1"
							label="储能数据"
							style="margin-top:-10px;"
						>
							<div
								v-if="bmsVolData && bmsVolData.length"
								class="electricBox"
							>
								<el-row>
									<el-col :span="24">
										<p class="electricText">
											<span class="electricTitle"
												>可充电储能子系统：</span
											>
											{{ bmsVolData.length || 0 }}个
										</p>
									</el-col>
									<p class="electricText">
										<span
											class="electricTitle"
											style="color:#4077BC;text-indent: 10px;"
											>{{
												bmsVolData[0].index
											}}号可充电储能子系统</span
										>
									</p>
									<el-col :span="12">
										<p
											class="electricText margin-top-bottom-5"
										>
											<span
												class="electricTitle"
												style="width:165px;"
												>子系统电压：</span
											>
											{{ bmsVolData[0].voltage }}V
										</p>
									</el-col>
									<el-col :span="12">
										<p
											class="electricText margin-top-bottom-5"
										>
											<span
												class="electricTitle"
												style="width:165px;"
												>子系统电流：</span
											>
											{{ bmsVolData[0].current }}A
										</p>
									</el-col>
									<el-col :span="12">
										<p
											class="electricText margin-top-bottom-5"
										>
											<span
												class="electricTitle"
												style="width:165px;"
												>单体电池总数：</span
											>
											{{ bmsVolData[0].volList.length }}个
										</p>
									</el-col>
									<el-col
										:span="12"
										v-if="bmsVolData[0].tempList"
									>
										<p
											class="electricText margin-top-bottom-5"
										>
											<span
												class="electricTitle"
												style="width:165px;"
												>温度探针总数：</span
											>
											{{
												bmsVolData[0].tempList.length
											}}个
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="electricText">
											<span class="electricTitle"
												>单体电池电压值列表</span
											>
										</p>
									</el-col>
									<el-col
										:span="24"
										style="height:100px; overflow-y: auto"
									>
										<span
											style="display: inline-block; color:#444444;font-weight: normal;padding:0 2px;"
											v-for="(item,
											index) of bmsVolData[0].volList"
											:key="index"
											>{{
												index + 1 ===
												bmsVolData[0].volList.length
													? item
													: item + ","
											}}</span
										>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="24">
										<p class="electricText">
											<span class="electricTitle"
												>探针温度值列表</span
											>
										</p>
									</el-col>
									<el-col
										:span="24"
										style="height:100px; overflow-y: auto"
									>
										<span
											style="display: inline-block;color:#444444;font-weight: normal;"
											v-for="(item,
											index) of bmsVolData[0].tempList"
											:key="index"
											>{{
												index + 1 ===
												bmsVolData[0].tempList.length
													? item
													: item + ","
											}}</span
										>
									</el-col>
								</el-row>
							</div>
						</el-tab-pane>
						<el-tab-pane name="ele2" label="极值数据">
							<div v-if="extremeData" class="electricBox">
								<el-row>
									<el-col :span="12">
										<p class="electricText">
											<span class="electricTitle"
												>最高电压电池子系统号：</span
											>
											{{ extremeData.maxVolIdx }}
										</p>
									</el-col>
									<el-col :span="12">
										<p class="electricText">
											<span class="electricTitle"
												>最高电压电池单体代号：</span
											>
											{{ extremeData.maxVolPos }}
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="12">
										<p class="electricText">
											<span class="electricTitle"
												>电池单体电压最高值：</span
											>
											{{ extremeData.maxVolVal }}V
										</p>
									</el-col>
									<el-col :span="12">
										<p class="electricText">
											<span class="electricTitle"
												>最低电压电池子系统号：</span
											>
											{{ extremeData.minVolIdx }}
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="12">
										<p class="electricText">
											<span class="electricTitle"
												>最低电压电池单体代号：</span
											>
											{{ extremeData.minVolPos }}
										</p>
									</el-col>
									<el-col :span="12">
										<p class="electricText">
											<span class="electricTitle"
												>电池单体电压最低值：</span
											>
											{{ extremeData.minVolVal }}V
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="12">
										<p class="electricText">
											<span class="electricTitle"
												>最高温度子系统号：</span
											>
											{{ extremeData.maxTempIdx }}
										</p>
									</el-col>

									<el-col :span="12">
										<p class="electricText">
											<span class="electricTitle"
												>最高温度探针序号：</span
											>
											{{ extremeData.maxTempPos }}
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="12">
										<p class="electricText">
											<span class="electricTitle"
												>最高温度值：</span
											>
											{{ extremeData.maxTempVal }}℃
										</p>
									</el-col>
									<el-col :span="12">
										<p class="electricText">
											<span class="electricTitle"
												>最低温度子系统号：</span
											>
											{{ extremeData.minVolIdx }}
										</p>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="12">
										<p class="electricText">
											<span class="electricTitle"
												>最低温度探针序号：</span
											>
											{{ extremeData.minTempPos }}
										</p>
									</el-col>
									<el-col :span="12">
										<p class="electricText">
											<span class="electricTitle"
												>最低温度值：</span
											>
											{{ extremeData.minTempVal }}℃
										</p>
									</el-col>
								</el-row>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</el-tab-pane>
			<el-tab-pane name="2" label="外设数据">
				<div class="tab-box tab-box-border">
					<el-tabs v-model="activePeripheralTab" stretch>
						<el-tab-pane name="tire" label="胎温胎压数据">
							<el-table
								v-if="TyreData"
								style="margin-top:5px;"
								highlight-current-row
								stripe
								size="mini"
								:data="TyreData"
							>
								<el-table-column
									prop="date"
									label="轮胎"
									align="center"
								>
									<template slot-scope="scope">
										<span>{{ scope.row.tyreNo }}</span>
									</template>
								</el-table-column>
								<el-table-column label="温度" align="center">
									<template slot-scope="scope">
										<span>{{ scope.row.tyreTemp }}℃</span>
									</template>
								</el-table-column>
								<el-table-column
									prop
									label="胎压"
									align="center"
								>
									<template slot-scope="scope">
										<span
											>{{ scope.row.tyreStress }}kPa</span
										>
									</template>
								</el-table-column>
								<el-table-column label="状态" align="center">
									<template slot-scope="scope">
										<span>{{ scope.row.tyreAlarm }}</span>
									</template>
								</el-table-column>
							</el-table>
						</el-tab-pane>
						<el-tab-pane name="fire" label="灭火装置数据">
							<el-table
								v-if="fireData"
								style="margin-top:5px;"
								highlight-current-row
								stripe
								size="mini"
								:data="fireData"
							>
								<el-table-column
									label="灭火装置"
									align="center"
								>
									<template slot-scope="scope">
										<span>{{ scope.row.index }}号灭火</span>
									</template>
								</el-table-column>
								<el-table-column
									label="预警级别"
									align="center"
								>
									<template slot-scope="scope">
										<span>{{ scope.row.name }}</span>
									</template>
								</el-table-column>
								<el-table-column
									label="故障等级"
									align="center"
								>
									<template slot-scope="scope">
										<span>{{ scope.row.address }}</span>
									</template>
								</el-table-column>
								<el-table-column label="故障码" align="center">
									<template slot-scope="scope">
										<span>{{ scope.row.status }}</span>
									</template>
								</el-table-column>
							</el-table>
						</el-tab-pane>
						<el-tab-pane name="watePump" label="冷却水泵装置数据">
							<el-table
								v-if="waterPumpData"
								style="margin-top:5px;"
								highlight-current-row
								stripe
								size="mini"
								:data="waterPumpData"
							>
								<el-table-column
									label="冷却水泵装置"
									align="center"
								>
									<template slot-scope="scope">
										<span
											>{{
												scope.row.watePumpNO
											}}号水泵</span
										>
									</template>
								</el-table-column>
								<el-table-column
									label="输入电压"
									align="center"
								>
									<template slot-scope="scope">
										<span>{{ scope.row.watePumpV }}</span>
									</template>
								</el-table-column>
								<el-table-column
									label="输入电流"
									align="center"
								>
									<template slot-scope="scope">
										<span>{{ scope.row.watePumpI }}</span>
									</template>
								</el-table-column>
								<el-table-column
									label="电机转速"
									align="center"
								>
									<template slot-scope="scope">
										<span>{{
											scope.row.watePumpSpeed
										}}</span>
									</template>
								</el-table-column>
								<el-table-column
									label="运行状态"
									align="center"
								>
									<template slot-scope="scope">
										<span>{{
											scope.row.watePumpStatus
										}}</span>
									</template>
								</el-table-column>
								<el-table-column
									label="报警信息"
									align="center"
								>
									<template slot-scope="scope">
										<span>{{
											scope.row.watePumpAlarm
										}}</span>
									</template>
								</el-table-column>
							</el-table>
						</el-tab-pane>
					</el-tabs>
				</div>
			</el-tab-pane>
		</el-tabs>
	</el-dialog>
</template>

<script>
export default {
	name: "alertData",
	props: {
		webSocketData: Object,
		show: Boolean,
	},
	updated() {
		if (this.webSocketData) {
			this.overallVehData = this.webSocketData.overallVehData;
			this.driveMotorData = this.webSocketData.driveMotorData;
			this.bmsTempData = this.webSocketData.bmsTempData;
			this.bmsVolData = this.webSocketData.bmsVolData;
			this.extremeData = this.webSocketData.extremeData;
		}
	},
	data() {
		return {
			activeCode: "1",
			activeElecTab: "ovrallData",
			activePeripheralTab: "tire",
			visible: false,

			overallVehData: {}, //整车数据
			driveMotorData: {},
			bmsTempData: {},
			bmsVolData: {},
			extremeData: {}, //极值数据

			TyreData: [],
			waterPumpData: [],
			fireData: [],
		};
	},
	watch: {
		show: {
			handler() {
				this.visible = this.show;
			},
			immediate: true,
		},
	},
	methods: {
		handleClose() {
			this.$emit("close");
		},
		openPlan() {},
	},
};
</script>

<style scoped></style>
