<template>
    <div class="list">
        <pl-table
            big-data-checkbox
            fixedColumnsRoll
            :use-virtual="true"
            :row-height="60"
            class="table"
            v-loading="loading"
            element-loading-text="拼命加载中"
            :border="true"
            :data="tableData"
            :row-key="getRowKey"
            style="width: 100%"
            :height="height"
            :max-height="850"
            :highlight-current-row="false"
            ref="table"
            stripe
            fit
            :row-style="{height: '60px'}"
            :cell-style="{padding: '5px 0'}"
            :header-cell-style="{ background: '#4077BC', color: '#fff' }"
            @select="selectRows"
            @select-all="selectAllRows"
        >
            <pl-table-column
                fixed
                width="55"
                align="center"
                v-if="selectMode === 'radio'"
            >
                <template slot-scope="scope">
                    <el-radio
                        class="table_radio"
                        v-model="radio"
                        :label="scope.$index"
                        @change.native.prevent="
                            getRadioRow(scope.$index, scope.row)
                        "
                        >&nbsp;</el-radio
                    >
                </template>
            </pl-table-column>

            <pl-table-column
                v-else-if="selectMode === 'check'"
                type="selection"
                width="55"
                align="center"
            ></pl-table-column>

            <pl-table-column
                fixed
                v-if="showIndex"
                align="center"
                :width="60"
                type="index"
                label="序号"
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.$index + 1 + pageSize * (pageIndex - 1)
                    }}</span>
                </template>
            </pl-table-column>

            <pl-table-column
                align="center"
                v-for="column in realColumns"
                :label="column.title"
                :prop="column.prop"
                :key="column.prop"
                :min-width="column.minWidth || column.labelWidth || 120"
                :show-overflow-tooltip="true"
            >
                <template slot-scope="scope">
                    <template v-if="column.image">
                        <img
                            v-if="scope.row[column.prop]"
                            @click="clickImg($event)"
                            :src="scope.row[column.prop]"
                            style="width:50px;height:50px;cursor:pointer;"
                        />
                        <span v-else>暂无图片</span>
                    </template>
                    <el-button
                        class="innerButton"
                        type="text"
                        size="small"
                        v-else-if="column.clicked"
                        @click="
                            handleEdit(scope.$index, column.index, scope.row)
                        "
                        >{{
                            column.formatter(
                                scope.$index,
                                column.prop,
                                scope.row
                            )
                        }}</el-button
                    >
                    <span v-else-if="column.formatter" :style="{color: scope.row['color']}">{{
                        column.formatter(scope.$index, column.prop, scope.row)
                    }}</span>
                    <span v-else :style="{color: scope.row['color']}">{{ scope.row[column.prop] }}</span>
                </template>
            </pl-table-column>

            <pl-table-column
                fixed="right"
                :width="operateWidth"
                label="操作"
                v-if="showOpColumn"
                align="center"
            >
                <template slot-scope="scope">
                    <el-row type="flex" justify="space-between">
                        <el-col
                            v-for="btn in getOpBtns(scope)"
                            :key="btn.index"
                        >
                            <el-button
                                :type="btn.type"
                                :disabled="btn.disable"
                                :size="btn.size"
                                class="innerButton"
                                :icon="btn.icon"
                                @click="
                                    handleEdit(
                                        scope.$index,
                                        btn.index,
                                        scope.row
                                    )
                                "
                            >
                                {{ btn.title }}
                            </el-button>
                        </el-col>
                    </el-row>
                </template>
            </pl-table-column>
        </pl-table>
        <div class="pagination">
            <el-pagination
                v-if="showPager"
                ref="pagination"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-sizes="pageRnage"
                :page-size="pageSize"
                :layout="'total, sizes, prev, pager, next, jumper'"
                :total="total"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
/* eslint-disable */ 
import _ from "loadsh";
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";
import { PlTable, PlTableColumn } from 'pl-table';
import 'pl-table/themes/index.css';
export default {
    props: {
        selectMode: {
            type: String,
            default() {
                return "none"; // none：不需要选择框  radio：单选 check：多选  且当多选，page组件不显示size
            }
        },
        height: Number,
        columns: {
            type: Array,
            required: true
        },
        data: Array, //每行显示的数据
        // selData: Array, // 选中的项
        selDataIds: Array, // 选中项的ids
        // selRadio: Number, //单选选中的项
        btnList: {
            type: Array,
            required: false
        },
        operateWidth: {
            type: String,
            default: "250"
        },
        showIndex: {
            type: Boolean,
            default: true
        },
        showPager: { // 显示分页
            type: Boolean,
            default: true
        },
        total: Number, // 当且仅当showPager为true

        rowKey: {
            type: String,
            default: "id"
        }, //多选情况下作为每行的key值,默认为id
        flip: {
            type: Boolean,
            default: false
        }, //是否开启翻页多选的功能,默认关闭
        pageRnage: {
            //page范围
            type: Array,
            default() {
                return [10, 50, 100, 200, 300, 400];
            }
        },
        isGenerate: { // item.btnList还是btnList
            type: Boolean,
            default: false
        }
    },

    name: "ctable",

    computed: {
        realColumns() {
            return _.filter(this.columns, v => {
                return !v.unShow;
            });
        },
        getRowKey() {
            if (this.flip) {
                if (this.rowKey) {
                    return this.rowKey;
                } else {
                    return "id";
                }
            } else {
                return "";
            }
        },
        getOpBtns() {
            //获取可操作的按钮
            return scope => {
                const btnProps = {
                    size: "small",
                    type: "text",
                    disabled: false
                };
                if (scope.row.btnList && scope.row.btnList.length) {
                    return scope.row.btnList.map(v => {
                        return {
                            ...btnProps,
                            ...v
                        };
                    });
                } else {
                    if (this.btnList && this.btnList.length) {
                        return this.btnList.map(v => {
                            return {
                                ...btnProps,
                                ...v
                            };
                        });
                    } else {
                        return [];
                    }
                }
            };
        },
        showOpColumn() {
            if (this.isGenerate) {
                return true
            }else{
                if (this.btnList && this.btnList.length) {
                    return true
                }else{
                    return false
                }
            }
        }
    },
    data() {
        return {
            loading: false,
            radio: -1,

            selection: [], // 选中行的数据

            tableData: [], // 数据

            pageIndex: 1,
            pageSize: 10,
            lock: false // 多选情况下锁，设置选中状态使用
        };
    },
    watch: {
        data: {
            handler() {
                this.tableData = _.cloneDeep(this.data);
                this.initCheckBoxStatus();
            },
            immediate: true,
            deep: true
        },
        selDataIds: {
            handler() {
                if (
                    this.tableData &&
                    this.tableData.length &&
                    this.rowKey &&
                    this.selDataIds &&
                    this.selDataIds.length
                ) {
                    if (this.selectMode === "radio") {
                        this.radio = this.tableData.findIndex(
                            v => v[this.rowKey] === _.head(this.selDataIds)
                        );
                    } else {
                        this.selDataIds.forEach(v => {
                            this.setCheckedRow(v);
                        });
                    }
                }
            },
            immediate: true
        },
        "tableData.length": function(val,oldVal) {
            if (oldVal == 1 && this.pageIndex > 1) {
                //防止删除不翻页
                this.pageIndex--;
                this.$emit("update", {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                });
            }
        },
        tableData: {
            handler() {
                this.loading = false;
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        clickImg(el) {
            new Viewer(
                el.currentTarget.parentElement.parentElement.parentElement
            );
            this.$emit("zoom", el);
        },
        reset() {
            this.selection = [];
            this.$refs.table.clearSelection();
            this.radio = -1;
            this.pageIndex = 1;
        },
        getRadioRow(index, item) {
            this.radio = index;
            this.selection = new Array(item);
            this.$emit("select", this.selection);
        },
        selectRows(selectRows, row) {
            if (this.flip) {
                this.pushItem(row);
            } else {
                this.selection = selectRows;
            }
            this.$emit("select", this.selection);
        },
        selectAllRows(selection) {
            if (this.flip) {
                this.pushItems(selection, selection.length);
            } else {
                this.selection = selection;
            }
            this.$emit("select", this.selection);
        },
        handleEdit(row, btnIndex, item) {
            this.$emit("cellClick", row, btnIndex, item);
        },
        handleSizeChange(val) {
            this.pageIndex = 1;
            this.pageSize = val;
            this.loading = true;
            this.$emit("update", {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            });
        },
        handleCurrentChange(val) {
            this.pageIndex = val;
            this.loading = true;
            this.$emit("update", {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            });
        },
        initCheckBoxStatus() {
            //翻页时候选中状态
            if (
                this.tableData &&
                this.tableData.length &&
                this.selectMode === "check"
            ) {
                this.$nextTick(() => {
                    this.lock = true;
                    if (this.selection && this.selection.length) {
                        this.selection.forEach(row => {
                            this.setCheckedRow(row);
                        });
                    }
                    this.lock = false;
                });
            }
        },
        pushItem(data) {
            // 往selection中推入数据,单个选择
            if (this.lock) {
                return false;
            }
            if (this.hasValueByRowKey(data, this.selection)) {
                this.selection = _.filter(this.selection, v => {
                    return v[this.rowKey] !== data[this.rowKey];
                });
            } else {
                this.selection.push(data);
            }
        },
        pushItems(array, isSelected) {
            // 全选或者取消全选
            if (this.lock) {
                return false;
            }
            if (isSelected) {
                this.selection = this.uniqArrayByKey(this.selection.concat(this.tableData),this.rowKey)
            } else {
                // 移除选中
                this.selection = _.pullAllBy(
                    this.selection,
                    this.tableData,
                    this.rowKey
                );
            }
        },
        hasValueByRowKey(data, array) {
            if (array && array.length) {
                const ids = array.map(v => v[this.rowKey]);
                return ids.includes(data[this.rowKey]);
            } else {
                return false;
            }
        },
        setCheckedRow(target) {
            this.$nextTick(() => {
                if (
                    this.tableData &&
                    this.tableData.length &&
                    this.rowKey &&
                    target
                ) {
                    let rowId;
                    if (typeof target == "string") {
                        rowId = target;
                    } else {
                        rowId = target[this.rowKey];
                    }
                    this.tableData.forEach(v => {
                        if (v[this.rowKey] === rowId) {
                            this.$refs.table.toggleRowSelection(v, true);
                            return;
                        }
                    });
                }
            });
        },
        getPager() {
            return {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            };
        },
        uniqArrayByKey(array,key) {
            return _.uniqBy(array,key)
        },
        // 增删改查
        query(url,data) {
            this.loading = true;
            const vm = this;
            setTimeout(() => { // 10秒钟关闭
                if (vm.loading) {
                    vm.loading = false;
                }
            },10 * 1000)
            this.$http.post(url,data).then(res => {
                if (res.detail && res.detail.list) {
                    this.tableData = res.data.list
                }else{
                    this.tableData = res.detail
                }
            })
        },
        delete(url,data,title) {
            if (data.length <= 0) {
                this.$message.warning({
                    message: "请选择要删除的列",
                    duration: 1000
                });
                return;
            }
            this.$confirm(`是否删除这些${title}`, "删除", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(async () => {
                const result = await this.$http.post(
                    url,
                    data
                );
                this.$message.success({
                    message: "删除成功!",
                    duration: 1000
                });
                this.$emit("update")
            });
        },
        update(url,data) {
            this.$http.post(url,data).then(() => {
                this.$message.success({
                    message: "更新成功!",
                    duration: 1000
                })
                this.$emit("update")
            })
        },
        add(url,data) {
            this.$http.post(url,data).then(() => {
                this.$message.success({
                    message: "新增成功!",
                    duration: 1000
                })
                this.$emit("update")
            })
        }
    },
    components: {
        PlTable,
        PlTableColumn
    }
};
</script>

<style lang="scss" scoped>
.list {
    background-color: #ffffff;
    .table {
        overflow: auto;
        font-size: 16px;
        .innerButton {
            font-size: 16px;
        }
    }
    .pagination {
        padding-top: 5px;
        height: 40px;
        .el-pagination {
            float: right;
        }
    }
}
</style>
<style type="text/css" lang="scss">
.table_radio {
    .el-radio__label {
        display: none;
    }
}
</style>
